<template>
  <div class="survey_multiple" :class="{ row: options.layout === 'line' }">
    <v-checkbox v-for="(choice, index) in localeChoices"
                :key="`${questionId}-${index}`"
                :label="choice.label"
                :value="`${choice.value}`"
                v-model="values[index]"
                :disabled="isReadSurvey || options.readonly"
                @click="onUpdateValue"
                :rules="[rules]"
                :hide-details="options.layout === 'line' || index !== localeChoices.length - 1"
    />
  </div>
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'
import { mapGetters } from 'vuex'

export default {
  name: 'SurveyQuestionMultiple',

  mixins: [questionMixins],

  data: function () {
    return {
      error: '',
      localeChoices: [],
      values: [],
    }
  },

  computed: {
    ...mapGetters(['user']),
    rules () {
      if (this.values.every(value => value === false)) return true

      if (this.options.valid === undefined) return true

      let selectedValues = this.values.filter(v => v !== null && v !== false)
                               .map(v => parseInt(v))
      const a = JSON.stringify(this.options.valid)
      const b = JSON.stringify(selectedValues)

      return a.indexOf(b) !== -1 || this.$t('survey.multiple.invalidChoices')
    },
  },

  mounted () {
    this.choices.forEach(choice => {
      this.values.push(this.value.includes(choice.value) ? `${choice.value}` : false)

      if (choice.choices) {
        const value = /^\{USER(?<path>(?::\w*)*)}$/.exec(choice.choices)
                                                   .groups
                                                   .path
                                                   .split(':')
                                                   .filter(element => element !== '')
                                                   .reduce((accumulator, currentValue) => {
                                                     return accumulator === undefined ? undefined
                                                                                      : accumulator[currentValue]
                                                   }, this.user)
        if (value === undefined) return

        if (typeof value.value === 'object') {
          value.value.forEach(element => {
            this.localeChoices.push({
              value: element,
              label: element,
            })
          })
        }
      } else {
        this.localeChoices.push({
          label: choice.label,
          value: choice.value,
        })
      }
    })
  },

  methods: {
    onUpdateValue () {
      this.$emit(
          'update-value',
          this.values.map((value, index) => {
            return value ? this.choices[index].value : false
          })
              .filter(value => value !== false),
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.survey_multiple {
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  &.row {
    flex-direction: row;
    align-items: center;

    .v-input::v-deep {
      margin: 15px 15px 20px 0;

      label {
        color: black;
      }
    }
  }

  &:not(.row) {
    .v-input::v-deep {
      &:not(:first-of-type) {
        margin: 0;
      }

      label {
        color: black;
      }
    }
  }
}

</style>
